/* General styles */
.container {
  margin: 0px 200px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HOME Styles */
.home-cnt {
  h1 {
    text-align: start;
  }
  .home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 2.4rem;
    }
  }
}

.home-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.unit-card {
  margin: 1rem 0.4rem;
  max-width: 360px;
}

/* New Unit styles */
.create-unit-cnt {
  max-width: 640px !important;
  h1 {
    text-align: start;
    margin-left: 1.6rem;
  }
  h2 {
    text-align: start;
  }
  section {
    padding: 1.6rem;
  }
  .basic-data {
    background-color: #c4c4c4;
    input {
      background-color: white;
      border-radius: 5rem;
    }
  }
  .buttons-botom {
    display: flex;
    justify-content: space-between;
    margin: 0rem 1.6rem;
  }
  .upload-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .upload-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.4rem 0rem;
    span {
      margin: auto 0rem;
    }
  }
}

/* BaseComponents styles */

.base-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Post view styles */
.post-view-container {
  .images {
    margin-top: 4rem;
    height: 240px;
    display: flex;
    justify-content: space-between;
    a {
      display: block;
      height: 100%;
      background-color: #7c7d7e;
    }
    a:nth-child(2) {
      margin: 0rem 1rem;
    }
  }
  .unit-description {
    margin: 4rem 0rem;
    .unit-detail {
      display: flex;
      flex-direction: column;
      text-align: start;
      p {
        border-top: 0.12rem solid #282c34;
        margin-bottom: 0rem;
        padding: 0.4rem 0rem;
      }
    }
  }
  .aditional-container {
    display: flex;
    justify-content: space-between;
    .cont {
      margin: 0rem 1rem;
    }
    aside {
      padding: 0.64rem 0rem;
      text-align: start;
      border-top: 0.12rem solid #282c34;
    }
  }
}

/* Post Validation form */

.post-validation-form {
  h3 {
    text-align: start;
    margin: 1rem 0rem;
  }
}

.switch-container {
  display: flex;
}
